.carousel {
  display: flex;
  flex-direction: column;
  /* padding: 10px; */
  position: relative;
}

.newPosition {
  top: 35% !important;

  /* @media(--xsmall) {
    height: 25px !important;
    width: 15px !important;
  } */
}

.arrowLeft {
  cursor: pointer;
  left: 0;
  padding: 12px;
  z-index: 5;

  /* @media (--xxsmall) {
    display: none;
  } */

  &.default img {
    transform: rotate(90deg);
  }

  &.scroll img {
    transform: rotate(180deg);
  }

  &.round img {
    transform: rotate(180deg);
  }

  &.round  {
    border-radius: 50%;
    box-shadow: 9px 18px 30px 0px rgba(2, 2, 2, 0.20);
    height: 24px;
    left: 8vw;
    padding: 0;
    width: 24px;

    @media(--medium) {
       height: 34px;
       width: 34px;
    }
  }

  &.top {
    top: -5%;
  }
}

.arrowRight {
  cursor: pointer;
  padding: 12px;
  z-index: 5;

  /* @media (--xxsmall) {
    display: none;
  } */

  &.default img {
    transform: rotate(-90deg);
  }

  &.scroll img {
    transform: initial;
  }

  &.round img {
    right: 8vw;
    transform: initial;
  }

  &.round  {
    border-radius: 50%;
    box-shadow: 9px 18px 30px 0px rgba(2, 2, 2, 0.20);
    height: 24px;
    padding: 0;
    right: 8vw;
    width: 24px;

    @media(--medium) {
       height: 34px;
       width: 34px;
    }
  }

  &.top {
    top: -5%;
  }
}

.newPadding {
  padding: 0 25px;
}

.carouselContent {
  display: flex;
  flex: 1 1 90%;
  margin: 0;
  -webkit-overflow-scrolling: touch;
  -webkit-scroll-snap-points-x: repeat(100%);
  scroll-snap-points-x: repeat(100%);
  -webkit-scroll-snap-type: mandatory;
  scroll-snap-type: x mandatory;
}

.carouselChild {
  scroll-snap-align: start end;
  scroll-snap-stop: always;
}

.carouselSelector {
  display: flex;
  flex: 1 1 10%;
  justify-content: center;
  left: 0;
  padding: 0 10px;
  /* margin: 0 auto; */
  position: sticky;
  top: 510px;
  width: 100vw;
  z-index: 10;

  @media(--medium) {
    padding: 0 100px;
  }
}

.carouselSelectorDesktop {
  bottom: 20px;
  display: flex;
  flex: 1 1 10%;
  justify-content: center;
  left: 0;
  padding: 0 10px;
  /* margin: 0 auto; */
  position: sticky;
  width: 100vw;

  @media(--medium) {
    padding: 0 100px;
  }
}

.circle {
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  height: 10px;
  margin: 0 5px;
  transition: opacity 0.5s ease;
  width: 10px;

  &.white {
    background: white;
    opacity: 0.3;

    &.active,
    &:hover {
      opacity: 1;
    }
  }

  &.black {
    background: black;
    border-radius: 0;
    height: 4px;
    opacity: 0.3;
    width: 36px;

    &.active,
    &:hover {
      background: var(--color-primary);
      opacity: 1;
    }
  }

  &.night-rider {
    background: var(--color-night-rider);
    opacity: 0.3;

    &.active,
    &:hover {
      opacity: 1;
    }
  }
}

.arrowContainer {
  display: flex;
  height: 0;
  justify-content: space-between;
  left: 0;
  padding: 0 10px;
  position: sticky;
  top: 45%;
  width: 100vw;
  z-index: 6;

  @media(--medium) {
     padding: 0 100px;
  }
}

