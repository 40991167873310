.container {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.2) 100%),
    linear-gradient(171deg, #fff 18.33%, #ffede0 122.14%);
  width: 100%;
}

.productCategoriesContainer {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 16px 0;
  width: 100%;

  @media (--large) {
    padding: 32px 0;
  }
}

.titleBar {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 auto 2px;
  padding: 0 16px;
  width: 100%;

  @media (--medium) {
    margin-bottom: 16px;
    padding: 0 20px;
  }

  @media (--xxlarge) {
    max-width: 2000px;
  }
}

.title {
  color: var(--black);
  font-family: var(--font-termina);
  font-size: 20px;
  font-weight: bold;

  @media (--medium) {
    font-size: 25px;
  }
}

.description {
  color: #545454;
  font-family: var(--font-brown);
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;

  @media (--medium) {
    font-size: 15px;
  }
}

.ctaContainer {
  display: none;

  @media (--medium) {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
  }
}

.ctaContainerMobile {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 0 16px;

  @media (--medium) {
    display: none;
  }
}

.descriptionCtaMobile {
  color: var(--color-primary);
  font-family: var(--font-brown);
  font-size: 12px;
  margin-top: 12px;
}

.descriptionCta {
  color: var(--color-primary);
  font-family: var(--font-brown);
  font-size: 12px;
}

.cta {
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  justify-content: center !important;
  margin-top: 16px;
  padding: 6px 5px;
  width: 250px;

  @media (--medium) {
    margin-top: 16px;
    padding: 6px 30px;
    width: 200px;
  }
}

.contentContainer {
  display: flex;
  flex-direction: row;
  margin: 10px auto 0;
  overflow: scroll;
  padding: 0 11px;
  width: 100%;

  @media (--medium) {
    flex-direction: row;
    margin-top: 50px;
    padding: 0 15px;
  }

  @media (--xxlarge) {
    max-width: 2000px;
  }
}

.productCategoryCard0,
.productCategoryCard1,
.productCategoryCard2,
.productCategoryCard3 {
  border: 1px solid #b1b1b1;
  border-radius: 8px;
  box-shadow: 0px 4px 8px 0px rgba(255, 135, 124, 0.32);
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 0 5px;
  min-width: 310px;
  padding: 8px;

  @media (--medium) {
    border-radius: 8px;
    margin: 0 5px 32px;
    min-width: 360px;
  }
}

.productCategoryCard0 {
  background: #ec7877;
  &:hover {
    box-shadow: 0px 4px 24px 0px rgba(255, 111, 97, 0.6);
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
}

.productCategoryCard1 {
  background: #028575;
  &:hover {
    box-shadow: 0px 4px 24px 0px rgba(255, 111, 97, 0.6);
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
}

.productCategoryCard2 {
  background: #7a991f;
  &:hover {
    box-shadow: 0px 4px 24px 0px rgba(255, 111, 97, 0.6);
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
}

.productCategoryCard3 {
  background: #d65699;

  &:hover {
    box-shadow: 0px 4px 24px 0px rgba(255, 111, 97, 0.6);
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
}

.productCategoryImageContainer {
  height: 224px;
  object-fit: contain;
  position: relative;
  width: 224px;

  @media (--medium) {
    height: 224px;
    width: 175px;
  }
}

.productCategoryInfo {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 16px;
  width: 230px;
  @media (--medium) {
    justify-content: flex-start;
    padding: 12px 16px;
  }

  @media (--xxlarge) {
    width: 70%;
  }
}

.productCategoryTitle {
  font-family: var(--font-termina);
  font-size: 16px;
  font-style: normal;
  font-weight: bold;
  line-height: 150%;
  margin-bottom: 0;
  @media (--medium) {
    font-size: 25px;
    margin-bottom: 8px;
  }
}

.productCategoryDescription {
  font-family: var(--font-brown);
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  height: auto;
  line-height: 150%;
  @media (--medium) {
    font-size: 13px;
    height: 110px;
  }
}

.productCategoryCta {
  align-items: center;
  color: white;
  font-size: 11px !important;
  justify-content: space-between;
  padding: 10px 12px !important;
  width: 100%;

  @media (--medium) {
    font-size: 14px !important;
  }

  @media (--xxlarge) {
    justify-content: center;
  }
}

.arrowImage {
  align-items: center;
  display: flex;
  margin-left: 0;

  @media (--xxlarge) {
    margin-left: 10px;
  }
}

.searchWhite {
  margin-right: 5px !important;
  padding-top: 6px;

  @media (--medium) {
    margin-right: !important;
  }
}

.arrowRight {
  height: 16px;
  margin-left: 2px;
}
