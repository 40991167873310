.link {
  color: inherit;

  &.withUnderline {
    text-decoration: underline !important;
  }

  &:hover {
    opacity: 0.5;
  }
}
