.section {
  /* background-color: #FF5F00; */
  display: flex;
  flex-direction: column;
  margin: -15px 0 -11px;
  /* max-width: 100vw; */
  position: relative;
  /* min-height: 80vh; */

  @media (--large) {
    align-items: center;
    flex-direction: row;
    margin: -40px 0 -5px;
    max-width: 100vw;
  }
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 2.5vw 0;
  position: relative;
  /* width: 100%; */

  @media (--large) {
    align-items: center;
    flex-direction: row;
  }
}

.image {
  height: 90vw;
  margin: 0;
  object-fit: cover;
  position: relative;
  width: 100%;

  @media (--medium) {
    height: 485px;
    object-fit: contain;
  }

  @media (--xxlarge) {
    height: 60vh;
  }
}

.imageContainer {
  background-size: cover;
  display: flex;
  flex-direction: column;
  height: 520px;
  justify-content: space-between;
  margin: 0;
  object-fit: contain;
  padding: 0;
  position: relative;
  width: 100vw;

  @media (--medium) {
    flex-direction: row;
    height: 545px;
    justify-content: flex-end;
    object-fit: contain;
    padding: 80px 145px 0 0;
  }

  @media (--xxlarge) {
    height: 70vh;
  }
}

.textContainer {
  display: none;
  justify-content: center;

  @media (--large) {
    height: 100%;
    width: 50vw;
  }
}

.textContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15px 18px;
  text-align: center;

  @media (--large) {
    height: 100%;
    justify-content: initial;
    max-width: 550px;
    padding: 30px 35px;
    text-align: initial;
    width: 600px;
  }
}

.heading {
  color: #f5f5fa;
  margin-bottom: 10px;

  @media (--xlarge) {
    font-size: 45px;
    font-weight: bolder;
    line-height: 48px;
  }
}

.subHeading {
  color: #f5f5fa;
  font-size: 18px;
  line-height: 30px;
  margin-top: 10px;
  width: 90%;
}

.subHeading1 {
  color: #f5f5fa;
  font-size: 15px;
  font-style: italic;
  font-weight: bold;
  line-height: 30px;
  margin-top: 10px;
  width: 100%;
}

.cta {
  margin: auto;
  width: 90%;

  @media (--large) {
    margin: initial;
    width: 240px;
  }

  @media (--xlarge) {
    margin-top: 40px;
  }
}

.ctaSkinTest {
  margin-top: 20px;

  @media (--xlarge) {
    font-size: 22px;
  }
}

.popUpModal {
  height: 300px;
  padding: 0 !important;
  width: 300px;

  @media (--medium) {
    height: 600px;
    width: 600px;
  }
}

.popUpLink {
  display: block;
  height: 300px;
  width: 300px;

  @media(--medium) {
    height: 600px;
    width: 600px;
  }
  
  &:hover {
    opacity: 0.75;
  }
}

.popUpImageDesktop {
  display: none;

  @media (--medium) {
    display: block;
  }
}

.popUpImageMobile {
  display: block;

  @media (--medium) {
    display: none;
  }
}

.popUpButton {
  left: 18%;
  margin: 0 auto;
  min-width: 40%;
  padding: 5px;
  position: absolute;
  text-align: center;
  top: 85.5%;
  width: 200px;

  @media (--medium) {
    left: 30%;
    padding: 0;
    top: 88.5%;
    width: 250px;
  }
}

.buttonPopUp {
  padding: 2.5px;
  @media(--medium) {
    padding: 10px;
  }
}

.logoImage {
  height: 88px;
  position: relative;
  width: 370px;

  @media (--medium) {
    height: 100px;
    width: 390px;
  }
}

.carouselDesktop {
  height: 531px;
  margin: 0;
  position: relative;

  @media (--medium) {
    height: 550px;
    margin: 60px 0 0 0;
  }

  @media (--xxlarge) {
    height: 70.5vh;
  }
}

.ctaContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  /* gap: 10px; */
  height: 50%;
  padding: 16px 24px 40px 24px;
  text-align: center;
  width: 100vw;

  @media (--medium) {
    align-items: flex-start;
    height: auto;
    padding: 0;
    position: absolute;
    right: 5%;
    text-align: left;
    top: 28%;
    width: 31%;
  }

  @media (--xlarge) {
    width: 37%;
  }
}

.container1 {
  align-items: center;
  display: flex;
  flex-direction: column;
  /* gap: 10px; */
  height: 50%;
  padding: 16px 24px 40px 24px;
  text-align: center;
  width: 100vw;

  @media (--medium) {
    align-items: center;
    height: auto;
    padding: 0;
    position: absolute;
    right: 7%;
    text-align: left;
    top: 15%;
    width: 31%;
  }

  @media (--xlarge) {
    width: 37%;
  }
}

.container2 {
  align-items: center;
  display: flex;
  flex-direction: column;
  /* gap: 10px; */
  height: 50%;
  padding: 16px 24px 40px 24px;
  text-align: center;
  width: 100vw;

  @media (--medium) {
    align-items: center;
    height: auto;
    padding: 0;
    position: absolute;
    right: 7%;
    text-align: left;
    top: 15%;
    width: 31%;
  }

  @media (--xlarge) {
    width: 37%;
  }
}

.titleHero {
  font-size: 18px;
  line-height: 140%;
  margin-top: 60px;

  @media (--medium) {
    font-size: 28px;
    margin-top: 0;
  }

  @media (--xlarge) {
    font-size: 32px;
  }

  @media (--xxlarge) {
    font-size: 35px;
  }

  &.noMargin {
    margin-top: 0;
  }
}

.titleHero2 {
  color: var(--color-black);
  font-size: 18px;
  line-height: 140%;
  margin-top: 60px;
  text-align: center;

  @media (--medium) {
    color: #603A37;
    font-size: 38px;
    font-weight: 900;
    margin-top: 0;
  }

  @media (--xlarge) {
    font-size: 38px;
  }

  @media (--xxlarge) {
    font-size: 35px;
  }

  &.noMargin {
    margin-top: 0;
  }
}

.descriptionHero {
  font-size: 12px;
  font-weight: 400;
  line-height: 150%;
  margin-top: 0;
  width: 100%;

  @media (--medium) {
    font-size: 14px;
    margin-top: 16px;
    width: 100%;
  }

  @media (--xlarge) {
    font-size: 18px;
  }

  @media (--xxlarge) {
    font-size: 22px;
  }
}

.newCtaWrapper {
  display: flex;
  width: 100%;
}

.newCta {
  color: #fce8db;
  margin-top: 20px;
  width: 95%;

  @media (--medium) {
    width: 65%;
  }

  @media (--xlarge) {
    width: 65%;
  }

  @media (--xxlarge) {
    font-size: 20px !important;
    height: 10%;
  }
}

.heroMobileImage {
  height: 40%;
  object-fit: contain;
  object-position: top;
}

.heroMobileImageWrapper {
  height: 100%;
  position: relative;
  width: 100vw;
}

.heroImageContainer {
  height: 50px;
  position: relative;
  width: 100px;
  @media (--medium) {
    height: 50px;
    width: 150px;
  }
}

.heroImageWrapperMobile {
  display: flex;
  height: 50px;
  position: relative;
  width: 100%;
}

.heroImageContainer1 {
  height: 75px;
  position: absolute;
  top: -10px;
  width: 200px;

  @media (--medium) {
    height: 100px;
    margin-top: -20px;
    position: relative;
    width: 370px;
  }

  &.default {
    left: 18vw;

    @media (--xsmall) {
      left: 17vw;
    }
  }
}

.imageStyle1 {
  height: 75px;
  position: absolute;
  top: -10px;
  width: 200px;

  @media (--medium) {
    height: 200px;
    margin-top: -20px;
    position: relative;
    width: 400px;
  }

  &.default {
    left: 18vw;

    @media (--xsmall) {
      left: 17vw;
    }
  }
}

.heroImageContainer2 {
  height: 75px;
  left: 40vw;
  position: absolute;
  top: -10px;
  width: 170px;

  @media (--medium) {
    height: 100px;
    left: 60px;
    margin-top: -20px;
    width: 370px;
  }
}

.addtionalComponent {
  display: block;
}

.heroContainer {
  height: 100%;
  width: 100%;

  @media (--medium) {
    height: 100%;
    width: 100%;
  }
}


.subtitleHero {
  font-size: 12px;
  font-weight: 400;
  line-height: 150%;
  width: 100%;
  
  @media (--medium) {
    font-size: 14px;
    margin-bottom: 30px;
    width: 100%;
  }

  @media (--xlarge) {
    font-size: 15px;
    margin-bottom: 30px;
  }

  @media (--xxlarge) {
    font-size: 18px;
    margin-bottom: 30px;
  }
}

.customStyleDefault {
  color: var(--color-primary);
  font-size: 15px;
  font-weight: 500;
  line-height: 150%;
  margin-top: 20px;
  text-align: center;
  width: 100%;
  
  @media (--medium) {
    color: var(--color-primary);
    font-size: 28px;
    margin-bottom: 10px;
    margin-top: 0;
  }

  @media (--xxlarge) {
    font-size: 20px;
  }
}

.customStyle1 {
  color: white;
  font-size: 15px;
  font-weight: 500;
  line-height: 150%;
  margin-top: 20px;
  text-align: center;
  width: 100%;
  
  @media (--medium) {
    color: #fce8db;
    font-size: 27px;
    margin-bottom: 10px;
    margin-top: 0;
  }

  @media (--xxlarge) {
    font-size: 20px;
  }
}

.customStyle2 {
  color: var(--color-black);
  font-size: 15px;
  font-weight: 500;
  line-height: 150%;
  margin-top: 10px;
  text-align: center;
  width: 100%;
  
  @media (--medium) {
    color: var(--color-primary);
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 20px;
    margin-top: 20px;
    text-align: center;
    width: 100%;
  }

  @media (--xlarge) {
    font-size: 15px;
  }

  @media (--xxlarge) {
    font-size: 20px;
  }
}

.contentCopy {
  color: #5F3937;
  font-family: var(--font-termina);
  font-size: 30px;
  font-weight: 800;
  margin-bottom: 20px;
  margin-left: -50px;
  text-align: center;
  width: 47%;
}

.titleWrapper {
  display: flex;
  justify-content: center;
  width: 85%;
}

.discountLabel {
  background-color: #fce8db;
  border-radius: 10px;
  color: #F0847C;
  font-family: var(--font-termina);
  padding: 10px 20px 0;
  text-align: center;

  & .titleLabel {
    font-weight: 700;
  }

  & .percentage {
    font-size: 80px;
    line-height: 100%;
  }

  & .symbol {
    font-family: var(--font-brown);
  }
}

.bannerMobile {
  filter: brightness(0) saturate(100%) invert(0%) sepia(5%) saturate(0%) hue-rotate(334deg) brightness(94%) contrast(105%);
}