.button {
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  font-family: var(--font-brown);
  font-size: 16px;
  justify-content: space-around;
  letter-spacing: 1px;
  line-height: 24px;
  outline: none;
  padding: 12px 16px;
  position: relative;

  @media (--xlarge) {
    font-size: 18px;
    line-height: 26px;
  }

  &:focus,
  &:hover {
    transition: all 0.3s;
  }

  /* Button Colors */
  &.primary {
    background: var(--color-primary);
    color: white;

    &:focus,
    &:hover {
      background: white;
      color: var(--color-primary);
    }
  }

  &.primaryShadow {
    background: var(--color-primary);
    box-shadow: 0px 4px 0px 0px #BB3E32;
    color: white;

    &:focus,
    &:hover {
      box-shadow: none;
    }
  }

  &.primaryNoHover {
    background: var(--color-primary);
    color: white;
  }

  &.primaryUnderline {
    border-bottom: 1px solid var(--color-primary);
    color: var(--color-primary);
    padding: 0;

    &:focus,
    &:hover {
      opacity: 0.5;
    }
  }

  &.primaryBorder {
    border: 1px solid var(--color-primary);
    color: var(--color-primary);
    padding: 10px;

    &:focus,
    &:hover {
      opacity: 0.5;
    }
  }

  &.primaryWhite {
    color: var(--color-primary);
    padding: 0;

    &:focus,
    &:hover {
      opacity: 0.5;
    }
  }

  &.primaryYellowShadow {
    background: white;
    box-shadow: -6px 6px var(--color-yellow-neon);
    color: var(--color-primary);

    &:focus,
    &:hover {
      box-shadow: -2px 2px var(--color-yellow-neon);
    }
  }

  &.primaryYellowShadowPrimary {
    background: var(--color-primary);
    box-shadow: -6px 6px var(--color-yellow-neon);
    color: var(--color-yellow-neon);

    &:focus,
    &:hover {
      box-shadow: -2px 2px var(--color-yellow-neon);
    }
  }

  &.purplePurpleShadow {
    background: #b873b6;
    border: 1px solid white;
    box-shadow: -6px 6px white;
    color: #FFF8EA;
    font-size: 14px;

    &:focus,
    &:hover {
      box-shadow: -2px 2px #b873b6;
    }
  }

  &.whitePurpleShadow {
    background: white;
    border: 1px solid #b873b6;
    box-shadow: -6px 6px #b873b6;
    color: var(--color-primary);

    &:focus,
    &:hover {
      box-shadow: -2px 2px #b873b6;
    }
  }


  &.whiteLightBlueShadow {
    background: white;
    box-shadow: -6px 6px #00D4E6;
    color: var(--color-primary);

    &:focus,
    &:hover {
      box-shadow: -2px 2px #00D4E6;
    }
  }

  &.whiteBlackBorder {
    background: white;
    border: 2px solid black;
    color: black;
    transition: all 0.2s;

    &:focus,
    &:hover {
      background: var(--color-primary);
      border: 2px solid var(--color-primary);
      color: white;
    }
  }

  &.whiteBlackBorderShadow {
    background: white;
    border: 2px solid black;
    box-shadow: -8px 8px 0px 0px rgba(187, 62, 50, 0.20);
    color: black;
    transition: all 0.2s;

    &:focus,
    &:hover {
      background: rgba(187, 62, 50, 0.20);
      border: 2px solid black;
      color: white;
    }
  }

  &.transparentBlackBorder {
    background: none;
    border: 2px solid black;
    color: black;
    transition: all 0.2s;

    &:focus,
    &:hover {
      background: var(--color-primary);
      border: 2px solid var(--color-primary);
      color: white;
    }
  }

  &.transparentBlackBorderNoHover {
    background: none;
    border: 2px solid black;
    color: black;
    transition: all 0.2s;
  }

  &.transparentWhiteBorder {
    background: none;
    border: 2px solid rgba(255, 255, 255, 0.30);
    color: white;
    transition: all 0.2s;

    &:focus,
    &:hover {
      background: var(--color-primary);
      border: 2px solid var(--color-primary);
      color: white;
    }
  }

  &.whiteBlackShadow {
    background: white;
    border: 2px solid black;
    box-shadow: -6px 6px black;
    color: black;

    &:focus,
    &:hover {
      box-shadow: -2px 2px black;
    }
  }

  &.whiteBrownShadow {
    background: white;
    border: 2px solid black;
    box-shadow: 0 4px 0 0 #BB3E32;
    color: black;

    &:focus,
    &:hover {
      box-shadow: 0 1px 0 0 #BB3E32;
    }
  }

  &.whitePrimaryShadow {
    background: white;
    border: 2px solid var(--color-primary);
    box-shadow: -6px 6px var(--color-primary-dark);
    color: var(--color-primary);

    &:focus,
    &:hover {
      box-shadow: -2px 2px var(--color-primary-dark);
    }
  }

  &.whiteYellowShadow {
    background: white;
    border: 2px solid var(--color-green-yellow);
    box-shadow: -6px 6px var(--color-green-yellow);
    color: black;

    &:focus,
    &:hover {
      box-shadow: -2px 2px var(--color-green-yellow);
    }
  }

  &.whiteBlueShadow {
    background: white;
    border: 2px solid var(--color-deep-blue);
    box-shadow: -6px 6px var(--color-deep-blue);
    color: var(--color-deep-blue);

    &:focus,
    &:hover {
      box-shadow: -2px 2px var(--color-deep-blue);
    }
  }

  &.whiteGreenShadow {
    background: white;
    border: 2px solid var(--color-green);
    box-shadow: -6px 6px var(--color-green);
    color: var(--color-green);

    &:focus,
    &:hover {
      box-shadow: -2px 2px var(--color-green);
    }
  }

  &.whiteBluePrimaryShadow {
    background: white;
    border: 2px solid var(--color-deep-blue);
    box-shadow: -6px 6px var(--color-deep-blue);
    color: var(--color-primary);

    &:focus,
    &:hover {
      box-shadow: -2px 2px var(--color-deep-blue);
    }
  }

  &.whitePinkShadow {
    background: white;
    border: 2px solid var(--color-pink);
    box-shadow: -6px 6px var(--color-pink);
    color: var(--color-pink);

    &:focus,
    &:hover {
      box-shadow: -2px 2px var(--color-pink);
    }
  }

  &.whiteYellowShadowBlueText {
    background: white;
    border: 2px solid var(--color-deep-blue);
    box-shadow: -6px 6px var(--color-green-yellow);
    color: var(--color-deep-blue);

    &:focus,
    &:hover {
      box-shadow: -2px 2px var(--color-green-yellow);
    }
  }

  &.whiteBlackBorderLimeShadow {
    background: white;
    border: 2px solid black;
    box-shadow: -6px 7px var(--color-green-lime);
    color: black;

    &:focus,
    &:hover {
      box-shadow: -2px 2px var(--color-green-lime);
    }
  }

  &.blackBox {
    background: black;
    color: white;

    &:focus,
    &:hover {
      background: grey;
    }
  }

  &.primaryBlackBorderBlackShadow {
    background: var(--color-primary);
    border: 2px solid black;
    box-shadow: -6px 6px black;
    color: white;

    &:focus,
    &:hover {
      box-shadow: -2px 2px black;
    }
  }

  &.green {
    background: #009874;
    color: white;

    &:focus,
    &:hover {
      background: #009874;
    }
  }

  &.disabled {
    background-color: #E5E5E5;
    color: #B1B1B1;
    cursor: not-allowed;
    opacity: 0.5;

    &:focus,
    &:hover {
      background-color: #E5E5E5;
      color: #B1B1B1;
    }
  }
}

.pulse {
  animation: pulsing 1.2s infinite;
  background: var(--color-primary);
  border-radius: 50%;
  height: 16px;
  position: absolute;
  right: 10px;
  top: 35%;
  width: 16px;
}

@keyframes pulsing {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 111, 97, 0.5);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(255, 111, 97, 0);
  }

  100% {
    box-shadow: 0 0 0 10px rgba(255, 111, 97, 0);
  }
}