.section {
  display: block;
  height: 40px;
  margin-top: 30px;
  min-height: 5vh;

  @media(--medium) {
    height: 20vh;
    margin-top: 0;
    min-height: 20vh;
  }
}

.container {
  display: flex;
  justify-content: center;
  /* padding: 30px 20% 0; */
}

.social {
  display: flex;
  flex-direction: row;
  height: 36px;
  margin: 5px;
  padding: 0;
  width: 40vw;

  @media(--medium) {
    height: 86px;
    margin: 16px;
    padding: 24px;
    width: 310px;
  }
}

.divider {
  border-right: 1px solid black;
  height: 36px;
  margin-top: 0;
  width: 2px;

  @media(--medium) {
    height: 86px;
    margin-top: 28px;
  }
}

.socialLeft {
  display: flex;
  flex-direction: row;
  height: 36px;
  margin: 5px;
  padding: 0;
  width: 40vw;

  @media(--medium) {
    height: 86px;
    margin: 16px 0 16px 16px;
    padding: 24px 0 24px 24px;
    width: 310px;
  }
}

.icon {
  align-items: center;
  display: flex;
  height: 24;
  margin: 5px 10px 5px 5px;
  width: 24px;

  @media(--medium) {
    height: 52px;
    /* margin: 5px auto; */
    width: 52px;
  }
}

.textCenter {
  font-family: var(--font-termina);
  font-size: 12px;
  font-weight: 500;
  text-align: left;

  @media(--medium) {
    font-size: 18px;
  }
}

.textCenterBrown {
  font-family: var(--font-brown);
  font-size: 12px;
  font-weight: 500;
  text-align: left;

  @media(--medium) {
    font-size: 18px;
  }
}

.textContainer {
  align-items: left;
  display: flex;
  flex-direction: column;
  height: 52px;
  width: 100%;
}
