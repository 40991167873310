.modal {
  background: white;
  display: flex;
  flex-direction: row;
  left: 50%;
  max-height: 90%;
  max-width: 80%;
  padding: 2.5px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.bottomsheet{
  background: white;
  border-radius: 16px 16px 0 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  padding: 2.5px;
  position: absolute;
  width: 100vw;
}

.modalContent {
  max-height: 100%;
  position: relative;
  width: 100%;
}

.closeButton {
  cursor: pointer;
  height: 20px;
  position: fixed;
  right: 20px;
  top: 15px;
  width: 20px;

  @media (--medium) {
    right: 20px;
    top: 20px;
  }
}

.roundedCloseButton{
  align-items: center;
  background-color: white;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  position: absolute;
  right: -20px;
  top: -20px;
  width: 40px;

  @media (--medium) {
    right: -23px;
    top: -23px;
  }
}
